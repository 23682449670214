// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.module.css */
.style_descriptifContainer__y02pS {
  text-align: center;
  margin: 20px 0;
  line-height: 1.6;
  color: white; /* Assurez-vous que cette couleur contraste bien avec le fond */
}

.style_title__WLuyi {
  font-family: 'LBA';
  font-weight: normal;
  font-size: 64px;

}

.style_p__UQ3ma {
  font-family: 'Lacquer';
  font-size: 20px;
  margin-left: 350px;
  margin-right: 350px;
}

@media (max-width: 900px){
  .style_title__WLuyi {
    font-size: 48px; /* Réduire la taille du titre pour les tablettes */
  }

  .style_p__UQ3ma {
    font-size: 18px; /* Réduire la taille du texte pour les tablettes */
    margin-left: 100px; /* Réduire les marges pour centrer le texte */
    margin-right: 100px;
  }
}

/* Styles pour les mobiles (en dessous de 768px) */
@media (max-width: 420px) {
  .style_title__WLuyi {
    font-size: 36px; /* Réduire davantage la taille du titre pour les téléphones */
  }

  .style_p__UQ3ma {
    font-size: 16px; /* Réduire davantage la taille du texte pour les téléphones */
    margin-left: 20px; /* Réduire les marges pour centrer le texte */
    margin-right: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DescriptifMonstres/style.module.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,YAAY,EAAE,+DAA+D;AAC/E;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;;AAEjB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe,EAAE,kDAAkD;EACrE;;EAEA;IACE,eAAe,EAAE,kDAAkD;IACnE,kBAAkB,EAAE,6CAA6C;IACjE,mBAAmB;EACrB;AACF;;AAEA,kDAAkD;AAClD;EACE;IACE,eAAe,EAAE,6DAA6D;EAChF;;EAEA;IACE,eAAe,EAAE,6DAA6D;IAC9E,iBAAiB,EAAE,6CAA6C;IAChE,kBAAkB;EACpB;AACF","sourcesContent":["/* style.module.css */\n.descriptifContainer {\n  text-align: center;\n  margin: 20px 0;\n  line-height: 1.6;\n  color: white; /* Assurez-vous que cette couleur contraste bien avec le fond */\n}\n\n.title {\n  font-family: 'LBA';\n  font-weight: normal;\n  font-size: 64px;\n\n}\n\n.p {\n  font-family: 'Lacquer';\n  font-size: 20px;\n  margin-left: 350px;\n  margin-right: 350px;\n}\n\n@media (max-width: 900px){\n  .title {\n    font-size: 48px; /* Réduire la taille du titre pour les tablettes */\n  }\n\n  .p {\n    font-size: 18px; /* Réduire la taille du texte pour les tablettes */\n    margin-left: 100px; /* Réduire les marges pour centrer le texte */\n    margin-right: 100px;\n  }\n}\n\n/* Styles pour les mobiles (en dessous de 768px) */\n@media (max-width: 420px) {\n  .title {\n    font-size: 36px; /* Réduire davantage la taille du titre pour les téléphones */\n  }\n\n  .p {\n    font-size: 16px; /* Réduire davantage la taille du texte pour les téléphones */\n    margin-left: 20px; /* Réduire les marges pour centrer le texte */\n    margin-right: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptifContainer": `style_descriptifContainer__y02pS`,
	"title": `style_title__WLuyi`,
	"p": `style_p__UQ3ma`
};
export default ___CSS_LOADER_EXPORT___;
