// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.module.css */
.style_navContainer__mrmTq {
  background-color: transparent;
  padding: 1px; /* Suppression du padding pour un gap de 0 avec le header */
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_navList__\\+jagb {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  gap: 40px; /* Espacement entre les éléments de la navbar */
}

.style_navItem__3m6zh {
  margin: 0;
}

.style_navLink__RQwbs {
  color: #fff;
  text-decoration: none;
  font-family: 'LBA', sans-serif;
  font-size: 24px;
}

.style_navLink__RQwbs:hover {
  text-decoration: underline;
  color: #fff;
}

/* Ajoutez cette media query */
@media (max-width: 920px) {
  .style_navContainer__mrmTq {
    display: none; /* Masquer la NavBar sur les écrans de 920px ou moins */
  }
}



  `, "",{"version":3,"sources":["webpack://./src/components/NavBarMondes/style.module.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,6BAA6B;EAC7B,YAAY,EAAE,2DAA2D;EACzE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,SAAS,EAAE,+CAA+C;AAC5D;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,WAAW;AACb;;AAEA,8BAA8B;AAC9B;EACE;IACE,aAAa,EAAE,uDAAuD;EACxE;AACF","sourcesContent":["/* style.module.css */\n.navContainer {\n  background-color: transparent;\n  padding: 1px; /* Suppression du padding pour un gap de 0 avec le header */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.navList {\n  list-style: none;\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  padding: 0;\n  gap: 40px; /* Espacement entre les éléments de la navbar */\n}\n\n.navItem {\n  margin: 0;\n}\n\n.navLink {\n  color: #fff;\n  text-decoration: none;\n  font-family: 'LBA', sans-serif;\n  font-size: 24px;\n}\n\n.navLink:hover {\n  text-decoration: underline;\n  color: #fff;\n}\n\n/* Ajoutez cette media query */\n@media (max-width: 920px) {\n  .navContainer {\n    display: none; /* Masquer la NavBar sur les écrans de 920px ou moins */\n  }\n}\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navContainer": `style_navContainer__mrmTq`,
	"navList": `style_navList__+jagb`,
	"navItem": `style_navItem__3m6zh`,
	"navLink": `style_navLink__RQwbs`
};
export default ___CSS_LOADER_EXPORT___;
