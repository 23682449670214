// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_habitantsContainer__3CbSF {
  padding: 20px;
  color: #fff;
}

.style_habitantsTitle__eGbcz {
  text-align: center;
  font-family: 'LBA', sans-serif;
  font-weight: normal;
  font-size: 48px;
  margin-bottom: 20px;
}

.style_habitantsGrid__MO8Gv {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 colonnes de taille égale par défaut */
  gap: 20px; /* Espace entre les cartes */
  justify-content: center;
}

/* Styles pour les écrans de 870 pixels ou moins (tablettes) */
@media (max-width: 920px) {
  .style_habitantsGrid__MO8Gv {
    grid-template-columns: repeat(3, 1fr); /* 3 colonnes sur tablette */
    gap: 15px;
  }

  .style_habitantsTitle__eGbcz {
    font-size: 36px; /* Réduire la taille du titre pour tablette */
  }
}

/* Styles pour les écrans de 470 pixels ou moins (mobiles) */
@media (max-width: 580px) {
  .style_habitantsGrid__MO8Gv {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur mobile */
    gap: 10px;
    margin: 10px;
  }

  .style_habitantsTitle__eGbcz {
    font-size: 32px; /* Réduire la taille du titre pour mobile */
  }
}

  
  
  `, "",{"version":3,"sources":["webpack://./src/components/ListPerso/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qCAAqC,EAAE,0CAA0C;EACjF,SAAS,EAAE,4BAA4B;EACvC,uBAAuB;AACzB;;AAEA,8DAA8D;AAC9D;EACE;IACE,qCAAqC,EAAE,4BAA4B;IACnE,SAAS;EACX;;EAEA;IACE,eAAe,EAAE,6CAA6C;EAChE;AACF;;AAEA,4DAA4D;AAC5D;EACE;IACE,qCAAqC,EAAE,0BAA0B;IACjE,SAAS;IACT,YAAY;EACd;;EAEA;IACE,eAAe,EAAE,2CAA2C;EAC9D;AACF","sourcesContent":[".habitantsContainer {\n  padding: 20px;\n  color: #fff;\n}\n\n.habitantsTitle {\n  text-align: center;\n  font-family: 'LBA', sans-serif;\n  font-weight: normal;\n  font-size: 48px;\n  margin-bottom: 20px;\n}\n\n.habitantsGrid {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr); /* 5 colonnes de taille égale par défaut */\n  gap: 20px; /* Espace entre les cartes */\n  justify-content: center;\n}\n\n/* Styles pour les écrans de 870 pixels ou moins (tablettes) */\n@media (max-width: 920px) {\n  .habitantsGrid {\n    grid-template-columns: repeat(3, 1fr); /* 3 colonnes sur tablette */\n    gap: 15px;\n  }\n\n  .habitantsTitle {\n    font-size: 36px; /* Réduire la taille du titre pour tablette */\n  }\n}\n\n/* Styles pour les écrans de 470 pixels ou moins (mobiles) */\n@media (max-width: 580px) {\n  .habitantsGrid {\n    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur mobile */\n    gap: 10px;\n    margin: 10px;\n  }\n\n  .habitantsTitle {\n    font-size: 32px; /* Réduire la taille du titre pour mobile */\n  }\n}\n\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"habitantsContainer": `style_habitantsContainer__3CbSF`,
	"habitantsTitle": `style_habitantsTitle__eGbcz`,
	"habitantsGrid": `style_habitantsGrid__MO8Gv`
};
export default ___CSS_LOADER_EXPORT___;
