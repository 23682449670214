// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets-img/Yggdrasil (2).png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Background.module.css */

.style_background__vpB-m {
  position: absolute; /* Utilisez absolute pour permettre le défilement */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* S'assure que le fond est derrière tout le reste */
}

.style_gradient__5CLwo {
  position: fixed; /* Utilisez fixed pour fixer l'élément par rapport à la fenêtre */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #646A7E, #646A7E, #786386, #8C5C8E);
  z-index: 1; /* Couche inférieure */
}

.style_tree__0eacy {
  position: relative; /* Utilisez fixed pour fixer l'élément par rapport à la fenêtre */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: 2; /* Couche supérieure */
}




  `, "",{"version":3,"sources":["webpack://./src/components/Background-Page/style.module.css"],"names":[],"mappings":"AAAA,yCAAyC;;AAEzC;EACE,kBAAkB,EAAE,mDAAmD;EACvE,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW,EAAE,oDAAoD;AACnE;;AAEA;EACE,eAAe,EAAE,iEAAiE;EAClF,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,uEAAuE;EACvE,UAAU,EAAE,sBAAsB;AACpC;;AAEA;EACE,kBAAkB,EAAE,iEAAiE;EACrF,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yDAA2D;EAC3D,sBAAsB;EACtB,4BAA4B;EAC5B,YAAY;EACZ,UAAU,EAAE,sBAAsB;AACpC","sourcesContent":["/* src/components/Background.module.css */\n\n.background {\n  position: absolute; /* Utilisez absolute pour permettre le défilement */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -1; /* S'assure que le fond est derrière tout le reste */\n}\n\n.gradient {\n  position: fixed; /* Utilisez fixed pour fixer l'élément par rapport à la fenêtre */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(180deg, #646A7E, #646A7E, #786386, #8C5C8E);\n  z-index: 1; /* Couche inférieure */\n}\n\n.tree {\n  position: relative; /* Utilisez fixed pour fixer l'élément par rapport à la fenêtre */\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-image: url('../../assets-img/Yggdrasil (2).png');\n  background-size: cover;\n  background-repeat: no-repeat;\n  opacity: 0.2;\n  z-index: 2; /* Couche supérieure */\n}\n\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `style_background__vpB-m`,
	"gradient": `style_gradient__5CLwo`,
	"tree": `style_tree__0eacy`
};
export default ___CSS_LOADER_EXPORT___;
