// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/MentionsLegales/MentionsLegales.module.css */
.style_mentionsContainer__R4Llj {
    color: white; /* Texte en blanc */
    margin: 50px; /* Marge de 50px */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/MentionsLegales/style.module.css"],"names":[],"mappings":"AAAA,yDAAyD;AACzD;IACI,YAAY,EAAE,mBAAmB;IACjC,YAAY,EAAE,kBAAkB;EAClC","sourcesContent":["/* src/pages/MentionsLegales/MentionsLegales.module.css */\n.mentionsContainer {\n    color: white; /* Texte en blanc */\n    margin: 50px; /* Marge de 50px */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionsContainer": `style_mentionsContainer__R4Llj`
};
export default ___CSS_LOADER_EXPORT___;
